<template>
    <b-card>
        <b-row>
            <!-- User Info: Left col -->
            <b-col cols="21" xl="4" class="d-flex justify-content-between">
                <!-- User Avatar & Action Buttons -->
                <div class="d-flex justify-content-start align-items-center">
                    <b-avatar :src="userData ? userData.avatar : 'https://via.placeholder.com'" size="104px" rounded />
                    <div class="d-flex flex-column ml-1">
                        <div class="mb-1">
                            <h4 class="mb-0">{{ userData.first_name }} {{ userData.last_name }}</h4>

                            <span class="card-text">{{ userData.cell_number }}</span>
                        </div>
                        <div class="d-flex flex-wrap">
                            <b-button :to="{ name: 'apps-users-edit', params: { id: userData.id } }" variant="outline-danger"> Edit </b-button>
                            <b-button variant="danger" class="ml-1" @click="deleteUser(userData.id)"> Delete </b-button>
                            <b-button variant="outline-danger" class="ml-1" @click="activateUser(userData.id)">
                                {{ activeButton }}
                            </b-button>
                        </div>
                    </div>
                </div>
            </b-col>

            <!-- Right Col: Table -->
            <b-col cols="12" xl="4">
                <table class="mt-2 mt-xl-0 w-100">
                    <tr>
                        <th class="pb-50">
                            <feather-icon icon="UserIcon" class="mr-75" />
                            <span class="font-weight-bold">First Name</span>
                        </th>
                        <td class="pb-50">
                            {{ userData.first_name }}
                        </td>
                    </tr>
                    <tr>
                        <th class="pb-50">
                            <feather-icon icon="UserIcon" class="mr-75" />
                            <span class="font-weight-bold">Last Name</span>
                        </th>
                        <td class="pb-50">
                            {{ userData.last_name }}
                        </td>
                    </tr>
                    <tr>
                        <th class="pb-50">
                            <feather-icon icon="CheckIcon" class="mr-75" />
                            <span class="font-weight-bold">email</span>
                        </th>
                        <td class="pb-50 text-capitalize">
                            {{ userData.email }}
                        </td>
                    </tr>
                    <tr>
                        <th class="pb-50">
                            <feather-icon icon="FlagIcon" class="mr-75" />
                            <span class="font-weight-bold">cell_number</span>
                        </th>
                        <td class="pb-50">
                            {{ userData.cell_number }}
                        </td>
                    </tr>
                    <tr>
                        <th class="pb-50">
                            <feather-icon icon="StarIcon" class="mr-75" />
                            <span class="font-weight-bold">Role</span>
                        </th>
                        <td class="pb-50 text-capitalize">{{ userData.role }}</td>
                    </tr>
                </table>
            </b-col>
            <b-col cols="12" xl="4">
                <table class="mt-2 mt-xl-0 w-100">
                    <tr>
                        <th class="pb-50">
                            <feather-icon icon="UserIcon" class="mr-75" />
                            <span class="font-weight-bold">Country</span>
                        </th>
                        <td v-if="userData.country_id !== null" class="pb-50">
                            {{ userData.country_id }}
                        </td>
                        <td v-else>not registered</td>
                    </tr>
                    <tr>
                        <th class="pb-50">
                            <feather-icon icon="UserIcon" class="mr-75" />
                            <span class="font-weight-bold">City</span>
                        </th>
                        <td v-if="userData.city_id !== null" class="pb-50">
                            {{ userData.city_id }}
                        </td>
                        <td v-else>not registered</td>
                    </tr>
                </table>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import { BCard, BButton, BAvatar, BRow, BCol } from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import router from "@/router";
// repository
import UserRepository from "@/abstraction/repository/userRepository";
import useUsersList from "./useUsersList";

const userRepository = new UserRepository();

export default {
    components: {
        BCard,
        BButton,
        BRow,
        BCol,
        BAvatar,
    },
    props: {
        // eslint-disable-next-line vue/require-prop-types
        userData: {
            // type: Object,
            required: true,
        },
    },
    data() {
        return {
            activeButton: "",
        };
    },
    mounted() {
        this.activeButton = this.userData.is_active ? "Deactivate" : "Activate";
        console.log(this.userData);
    },
    methods: {
        async deleteUser(userId) {
            await userRepository.delete(userId);
            await router.push({ name: "apps-users-list" });
        },
        async activateUser(userId) {
            await userRepository.activate(userId);
            this.activeButton = this.activeButton === "Activate" ? "Deactivate" : "Activate";
            // await router.push({ name: 'apps-users-list' })
        },
    },
    setup() {
        const { resolveUserRoleVariant } = useUsersList();
        return {
            avatarText,
            resolveUserRoleVariant,
        };
    },
};
</script>

<style></style>
