<template>
  <div v-if="form">
    <template>
      <!-- First Row -->
      <b-row>
        <b-col cols="12">
          <user-view-user-info-card :user-data="form" />
        </b-col>
      </b-row>

      <!-- <b-row>
        <b-col cols="12">
          <user-view-user-permissions-card />
        </b-col>
      </b-row> -->
    </template>
  </div>
</template>

<script>
import router from '@/router'
import { ref, onMounted } from '@vue/composition-api'
import {
    BRow, BCol, BAlert, BLink,
} from 'bootstrap-vue'
import UserRepository from '@/abstraction/repository/userRepository'
import UserViewUserInfoCard from './UserViewUserInfoCard.vue'
// import UserViewUserPermissionsCard from "./UserViewUserPermissionsCard.vue";
const userRepository = new UserRepository()

export default {
    components: {
        BRow,
        BCol,
        BAlert,
        BLink,

        // Local Components
        UserViewUserInfoCard,
    // UserViewUserPermissionsCard,
    },
    setup() {
        const form = ref(null)
        const userId = ref(router.currentRoute.params.id)
        onMounted(async () => {
            if (userId.value) {
                const resource = await userRepository.show(userId.value)
                form.value = resource
            }
        })
        return {
            form,
        }
    },
}
</script>

<style></style>
